import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ContentPageLayout from "../../components/ContentPageLayout"
import Seo from "../../components/seo"
import { getLegalPages } from "../../api/content"
import ReactMarkdown from "react-markdown"

const SettingsTermsPage = () => {
  const [privacy, setPrivacy] = React.useState()

  React.useEffect(() => {
    getLegalPages().then(response => {
      if (response.ok) {
        response.json().then(json => {
          // console.log(json)
          setPrivacy(json)
        })
      }
    })
  }, [])
  return (
    <ContentPageLayout>
      <Seo title="Aviso de Privacidad" />
      <h1 className="text-2xl ml-4 mt-16 md:mt-12 md:text-3xl md:mx-10">
        AVISO DE PRIVACIDAD CLIENTES SPORT CITY, S.A. DE C.V.
      </h1>
      <>
        {privacy ? (
          <ReactMarkdown className="p-8 md:mx-14">
            {privacy.avisoDePrivacidad}
          </ReactMarkdown>
        ) : null}
      </>
    </ContentPageLayout>
  )
}

export default SettingsTermsPage
